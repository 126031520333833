<template>
	<div class="footer">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 margin-top-12">
					<div class="float-left pull-none">
						<p class="copy-rights text-muted font-13 line-height-36">版权所有 {{ new Date().getFullYear() }} ©
							{{ company }}</p>
					</div>
					<div class="float-right pull-none">
						<ul class="list-inline line-height-36">
							<li class="list-inline-item">
								<a class="text-muted font-13"
									href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37079402001605"
									target="_blank">
									<img src="@/assets/images/bg/plice.png"
										style="width:22px;margin-top:-4px;padding-right:12px;">{{ gnbn }}
								</a>
							</li>
							<li class="list-inline-item">
								<a class="text-muted font-13" href="http://beian.miit.gov.cn" target="_blank">
									{{ icp }}
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'Footer',
	props: {
		company: {
			type: String,
			default: '山东纪元信息科技有限公司'
		},
		gnbn: {
			type: String,
			default: '鲁公网安备37079402001605号'
		},
		icp: {
			type: String,
			default: '鲁ICP备18035127号-1'
		}
	},
	methods: {}
}
</script>
